import { graphql } from 'gatsby'
import Img, { FluidObject } from 'gatsby-image'
import React, { FC } from 'react'
import Container from '../Atoms/Container'
import Wrapper from '../Atoms/Wrapper'
import Entry from './Entry'
import Form from '../Molecules/Form'

import { breakpoint, size } from '../tokens'

export interface IPageContentModuleProps {
  id: string
  textContentNode: {
    childMarkdownRemark: {
      html: string
    }
  }
  singleImage: {
    alt: string
    fluid: FluidObject
  }
}

interface IPageTemplateProps {
  data: {
    datoCmsPage: {
      slug: string
      id: string
      title: string
      description: string
      featuredImage: {
        alt: string
        fluid: FluidObject
      }
      singleImage: {
        alt: string
        fluid: FluidObject
      }
      pageContentModules: IPageContentModuleProps[]
    }
  }
}

const PageTemplate: FC<IPageTemplateProps> = ({ data }) => {
  const { datoCmsPage } = data
  return (
    <Entry pageTitle={datoCmsPage.title} pageDescription={datoCmsPage.description}>
      {datoCmsPage.featuredImage ? (
        <Container maxWidth="36em" bufferTop={size.quad}>
          <Img fluid={datoCmsPage.featuredImage.fluid} />
        </Container>
      ) : null}
      <Wrapper>
        <Container
          maxWidth={breakpoint.small}
          bufferTop={datoCmsPage.featuredImage ? size.quad : null}
        >
          <h1>{datoCmsPage.title}</h1>
        </Container>
        {datoCmsPage.pageContentModules.map((module) => (
          <Container
            maxWidth="36em"
            key={module.id}
            dangerouslySetInnerHTML={{ __html: module.textContentNode.childMarkdownRemark.html }}
          />
        ))}
      </Wrapper>
      {/* {datoCmsPage.singleImage ? (
        <Container maxWidth="36em">
          <Img fluid={datoCmsPage.singleImage.fluid} />
        </Container>
      ) : null} */}
      {datoCmsPage.slug === 'contact' ? (
        <Wrapper>
          <Container maxWidth={breakpoint.small}>
            <Form />
          </Container>
        </Wrapper>
      ) : null}
    </Entry>
  )
}

export default PageTemplate

export const query = graphql`
  query PageQuery($slug: String!) {
    datoCmsPage(slug: { eq: $slug }) {
      id
      slug
      title
      description
      featuredImage {
        alt
        fluid(maxWidth: 600, imgixParams: { fm: "jpg", auto: "compress" }) {
          ...GatsbyDatoCmsFluid
        }
      }
      pageContentModules {
        id
        textContentNode {
          childMarkdownRemark {
            html
          }
        }
        singleImage {
          alt
          fluid(maxWidth: 600, imgixParams: { fm: "jpg", auto: "compress" }) {
            ...GatsbyDatoCmsFluid
          }
        }
      }
    }
  }
`
